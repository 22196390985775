$ratio1: 0.25rem; // 4px
$ratio2: 0.50rem; // 8px
$ratio3: 0.75rem; // 12px
$ratio4: 1.00rem; // 16px
$ratio5: 1.25rem; // 20px
$ratio6: 1.50rem; // 24px
$ratio7: 1.75rem; // 28px
$ratio8: 2.00rem; // 32px

// ******************
// WIDTH

.w100 { width: 100%;}
.w75 { width: 75%;}
.w66 { width: 66.6%;}
.w50 { width: 50%;}
.w33 { width: 33.3%;}
.w25 { width: 25%;}
.w0 { width: 0;}

// ******************
// MARGINS

.m0 { margin: 0;}
.m1 { margin: $ratio1; }
.m2 { margin: $ratio2; }
.m3 { margin: $ratio3; }
.m4 { margin: $ratio4; }
.m5 { margin: $ratio5; }
.m6 { margin: $ratio6; }
.m7 { margin: $ratio7; }
.m8 { margin: $ratio8; }
.mauto { margin: 0 auto; }

.mt0 { margin-top: 0; }
.mt1 { margin-top: $ratio1; }
.mt2 { margin-top: $ratio2; }
.mt3 { margin-top: $ratio3; }
.mt4 { margin-top: $ratio4; }
.mt5 { margin-top: $ratio5; }
.mt6 { margin-top: $ratio6; }
.mt7 { margin-top: $ratio7; }
.mt8 { margin-top: $ratio8; }

.mr0 { margin-right: 0; }
.mr1 { margin-right: $ratio1; }
.mr2 { margin-right: $ratio2; }
.mr3 { margin-right: $ratio3; }
.mr4 { margin-right: $ratio4; }
.mr5 { margin-right: $ratio5; }
.mr6 { margin-right: $ratio6; }
.mr7 { margin-right: $ratio7; }
.mr8 { margin-right: $ratio8; }

.mb0 { margin-bottom: 0; }
.mb1 { margin-bottom: $ratio1; }
.mb2 { margin-bottom: $ratio2; }
.mb3 { margin-bottom: $ratio3; }
.mb4 { margin-bottom: $ratio4; }
.mb5 { margin-bottom: $ratio5; }
.mb6 { margin-bottom: $ratio6; }
.mb7 { margin-bottom: $ratio7; }
.mb8 { margin-bottom: $ratio8; }

.ml0 { margin-left: 0; }
.ml1 { margin-left: $ratio1; }
.ml2 { margin-left: $ratio2; }
.ml3 { margin-left: $ratio3; }
.ml4 { margin-left: $ratio4; }
.ml5 { margin-left: $ratio5; }
.ml6 { margin-left: $ratio6; }
.ml7 { margin-left: $ratio7; }
.ml8 { margin-left: $ratio8; }

.my0 { margin-top: 0; margin-bottom: 0; }
.my1 { margin-top: $ratio1; margin-bottom: $ratio1; }
.my2 { margin-top: $ratio2; margin-bottom: $ratio2; }
.my3 { margin-top: $ratio3; margin-bottom: $ratio3; }
.my4 { margin-top: $ratio4; margin-bottom: $ratio4; }
.my5 { margin-top: $ratio5; margin-bottom: $ratio5; }
.my6 { margin-top: $ratio6; margin-bottom: $ratio6; }
.my7 { margin-top: $ratio7; margin-bottom: $ratio7; }
.my8 { margin-top: $ratio8; margin-bottom: $ratio8; }

.mx0 { margin-left: 0; margin-right: 0; }
.mx1 { margin-left: $ratio1; margin-right: $ratio1; }
.mx2 { margin-left: $ratio2; margin-right: $ratio2; }
.mx3 { margin-left: $ratio3; margin-right: $ratio3; }
.mx4 { margin-left: $ratio4; margin-right: $ratio4; }
.mx5 { margin-left: $ratio5; margin-right: $ratio5; }
.mx6 { margin-left: $ratio6; margin-right: $ratio6; }
.mx7 { margin-left: $ratio7; margin-right: $ratio7; }
.mx8 { margin-left: $ratio8; margin-right: $ratio8; }

// ******************
// PADDING

.p0 { padding: 0; }
.p1 { padding: $ratio1; }
.p2 { padding: $ratio2; }
.p3 { padding: $ratio3; }
.p4 { padding: $ratio4; }
.p5 { padding: $ratio5; }
.p6 { padding: $ratio6; }
.p7 { padding: $ratio7; }
.p8 { padding: $ratio8; }

.pt0 { padding-top: 0; }
.pt1 { padding-top: $ratio1; }
.pt2 { padding-top: $ratio2; }
.pt3 { padding-top: $ratio3; }
.pt4 { padding-top: $ratio4; }
.pt5 { padding-top: $ratio5; }
.pt6 { padding-top: $ratio6; }
.pt7 { padding-top: $ratio7; }
.pt8 { padding-top: $ratio8; }

.pr0 { padding-right: 0; }
.pr1 { padding-right: $ratio1; }
.pr2 { padding-right: $ratio2; }
.pr3 { padding-right: $ratio3; }
.pr4 { padding-right: $ratio4; }
.pr5 { padding-right: $ratio5; }
.pr6 { padding-right: $ratio6; }
.pr7 { padding-right: $ratio7; }
.pr8 { padding-right: $ratio8; }

.pb0 { padding-bottom: 0; }
.pb1 { padding-bottom: $ratio1; }
.pb2 { padding-bottom: $ratio2; }
.pb3 { padding-bottom: $ratio3; }
.pb4 { padding-bottom: $ratio4; }
.pb5 { padding-bottom: $ratio5; }
.pb6 { padding-bottom: $ratio6; }
.pb7 { padding-bottom: $ratio7; }
.pb8 { padding-bottom: $ratio8; }

.pl0 { padding-left: 0; }
.pl1 { padding-left: $ratio1; }
.pl2 { padding-left: $ratio2; }
.pl3 { padding-left: $ratio3; }
.pl4 { padding-left: $ratio4; }
.pl5 { padding-left: $ratio5; }
.pl6 { padding-left: $ratio6; }
.pl7 { padding-left: $ratio7; }
.pl8 { padding-left: $ratio8; }

.py0 { padding-top: 0; padding-bottom: 0; }
.py1 { padding-top: $ratio1; padding-bottom: $ratio1; }
.py2 { padding-top: $ratio2; padding-bottom: $ratio2; }
.py3 { padding-top: $ratio3; padding-bottom: $ratio3; }
.py4 { padding-top: $ratio4; padding-bottom: $ratio4; }
.py5 { padding-top: $ratio5; padding-bottom: $ratio5; }
.py6 { padding-top: $ratio6; padding-bottom: $ratio6; }
.py7 { padding-top: $ratio7; padding-bottom: $ratio7; }
.py8 { padding-top: $ratio8; padding-bottom: $ratio8; }

.px0 { padding-left: 0; padding-right: 0; }
.px1 { padding-left: $ratio1; padding-right: $ratio1; }
.px2 { padding-left: $ratio2; padding-right: $ratio2; }
.px3 { padding-left: $ratio3; padding-right: $ratio3; }
.px4 { padding-left: $ratio4; padding-right: $ratio4; }
.px5 { padding-left: $ratio5; padding-right: $ratio5; }
.px6 { padding-left: $ratio6; padding-right: $ratio6; }
.px7 { padding-left: $ratio7; padding-right: $ratio7; }
.px8 { padding-left: $ratio8; padding-right: $ratio8; }
