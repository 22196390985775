.section-image-text {
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: calc(100vw * 900 / 1940);

    .image {
        // max-height: calc(100vw);
        padding: 0;
    }

    .text {
        z-index: 10;

        .title, .subtitle, h4 {
            @extend .text-color-header;
            @extend .font-1-light;
            margin-bottom: 50px;
            // margin-top: 50px;
        }

        .pretitle {
            text-transform: uppercase;
        }

        .title {
            font-size: 3vw;
        }

        .subtitle, h4 {
            font-size: 1.5vw;
            font-weight: 400;
            text-transform: uppercase;
        }

        p {
            @extend .font-1;
            font-size: 1.2vw;
            line-height: 1.8;
            margin-bottom: 3vw;
        }

        h5 {
            font-size: 1.8vw;
            font-weight: 300;
            text-transform: uppercase;
            margin-bottom: 15px;
            margin-top: 15px;
        }

        .straight-list ul, ul, div.blue {
            padding-left: 10px;
            padding-right: 0px;

            li {
                font-size: 1.2vw;
                font-weight: 500;
                margin-bottom: 20px;
                line-height: 1.5;
            }
        }

        .blue, div.lines ul, ul {
            margin-top: -1vw;
            margin-left: -8.33%;
            margin-bottom: 2vw;
            width: 50vw;
            list-style: none;
        }

        .straight-list ul {
            margin-top: 0;
            margin-left: 0;
            margin-bottom: 1em;
            width: 100%;
            list-style: disc;
        }

        ul {
            li {
                background-image: linear-gradient(to right, $color-blue, $color-blue-dark);
                color: $color-white;
                margin-bottom: 2px;
                padding: 20px 50% 20px 4.165%;
            }
        }

        .straight-list {
            ul {
                li {
                    background-image: none;
                    color: $color-black;
                    margin-bottom: 20px;
                    padding: 0;
                }
            }
        }

        div.lines ul {
            li {
                color: $color-black;
            }
        }

        div.blue {
            background-image: linear-gradient(to right, $color-blue, $color-blue-dark);
            padding: 20px 50% 20px calc(8.33% - 10px);
            color: $color-white;
            margin-bottom: 2px;
            margin-left: calc(-8.33% + 10px);

            p {
                margin: 0px;
                color: $color-white;
                line-height: 1.3;
            }

            h4 {
                margin-top: 0;
                color: $color-white;
            }
        }

        div.lines ul {
            li {
                padding: 20px 40% 20px 4.165%;
                margin-bottom: 2px;
                border-bottom: 1px $color-blue solid;
                background-image: none;

                &:last-child {
                    border-bottom: none;
                }
            }

            div.phone {
                font-weight: 400;
            }
        }

        p.small {
            font-size: 0.9vw;
            font-weight: 600;
        }
    }

    @media screen and (max-width: $size-sm-max) {
        .text {
            .title, h3 {
                font-size: 2em;
            }

            .subtitle, h4 {
                font-size: 1.7em;
            }

            p {
                font-size: 1em;
            }

            p.small {
                font-size: 0.9em;
            }

            h5 {
                font-size: 1.5em;
            }

            ul {
                li {
                    font-size: 1em;
                }
            }

            ul.blue, div.lines ul, div.blue {
                width: 100vw;
                list-style: none;

                li {

                }
            }
            .straight-list ul, ul, div.blue {
                li {
                    font-size: $font-size-16;
                }
            }
        }
    }
    @media screen and (max-width: $size-xs-max) {
        .text {
            .straight-list ul, ul, div.blue {
                li {
                    font-size: $font-size-16;
                }
            }
        }
    }
}


