.section-videos {
    text-align: center;
    padding: 100px 10%;
    .item {
        margin-bottom: 50px;
        .caption h3 {
            font-weight: 500;
        }
        .video-container {
            position: relative;
            padding-bottom: 56.25%;
            padding-top: 30px; height: 0; overflow: hidden;

            iframe, object, embed {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}
