.section-burst-icon {
    a {
        position:relative;
        padding: 40px;
        img {
            transition: 0.3s all ease-in;
        }
        .overlay {
            mix-blend-mode: hue;
            background-color: grey;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            transition: all 0.3s ease-in;
        }
        &:hover {
            img {
                transform: scale(1.1,1.1);
                transition: 0.3s all ease-in;
            }
            .overlay {
                opacity: 1;
                transition: all 0.3s ease-out;
                // IE 10 and 11
                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    opacity: 0;
                }
                // For IE and Edge
                @supports (-ms-ime-align:auto) {
                    opacity: 0;
                }
            }
        }
    }

}