// CSS Variables for the project.

// Mettre les différentes variables tirées du fichier psd du designer

//********************
// FONTS
// >> Allez configurer les fonts dans _fonts.scss

// font size dans le document PSD:
$font-size-base: 18px;
$font-size-10: 10px;
$font-size-12: 12px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-24: 24px;
$font-size-26: 26px;
$font-size-28: 28px;
$font-size-30: 30px;
$font-size-32: 32px;
$font-size-36: 36px;
$font-size-40: 40px;
$font-size-46: 46px;
$font-size-50: 50px;
$font-size-60: 60px;
$font-size-64: 64px;
$font-size-70: 70px;
$font-size-80: 80px;

/********************/
// COLORS
//
$color-black: #000;
$color-white: #fff; // needed
$color-grey: #adaaaa;
$color-blue: #218cb8;
$color-blue-dark: #1e6481;
$color-grey-light: lighten($color-grey, 20%);
$color-1: #a08606; // needed (gold)
$color-2: #a08606; // needed (red)

// TEXT COLORS

$color-text-base: $color-black; // Mettre la couleur
$color-text-punch: $color-blue; // Mettre la couleur
$color-text-contrast: $color-white; // Mettre la couleur

$color-text-base-light: lighten($color-text-base, 20%);
$color-text-punch-light: lighten($color-text-punch, 20%);
$color-text-contrast-light: lighten($color-text-contrast, 20%);
$color-text-base-dark: darken($color-text-base, 20%);
$color-text-punch-dark: darken($color-text-punch, 20%);
$color-text-contrast-dark: darken($color-text-contrast, 20%);

// BACKGROUNDS

$bg-color-body: $color-white; // needed
$bg-color-nav: $color-white; // needed
$bg-color-nav-highlight: $color-white; // needed
$bg-color-black: $color-black; // needed
$bg-color-transparent: rgba(0,0,0,0.4);
$bg-color-footer: #262523; // needed
$bg-color-primary: $color-1; // needed
$bg-color-secondary: $color-2; // needed


// ********************
// RESPONSIVE
//

$size-xs-max: 767px;
$size-sm-min: 768px;
$size-sm-max: 991px;
$size-md-min: 992px;
$size-md-max: 1529px;
$size-lg-min: 1530px;

$size-nav-shift-breakpoint: 1529px; // change height
$size-nav-toggle-breakpoint: 1333px; // show mobile menu
$size-slider-breakpoint: 480px;

// ********************
// SIZES
//

$size-footer-height: 97px; // needed
$size-footer-width-desktop: 50%; // needed
$size-footer-width-mobile: 100%; // needed
$size-nav-height: 100px; // needed
$size-nav-height-mobile: 75px; // needed
$size-nav-subitem: 25px; // needed
$size-nav-toggle-bar-height: 2px; // needed

$size-info-strip: 50px;  // theme

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'fonts'; // utility classes for fonts
@import 'spacing'; // utility classes for spacing
@import 'colors'; // utility classes for colors
@import 'flex'; // utility classes for flex box

@import 'layerslider';
@import 'animation';
