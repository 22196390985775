.form {
    .form-control {
        margin-bottom: 0.8em;
    }
    .g-recaptcha {
        & > div {
            margin: 0 auto;
        }
    }
    .btn, input, textarea {
        border-radius: 0;
        border: 1px solid $color-grey;
        color: $color-blue;
    }
    .btn {
        background-color: $color-black;
        border: 2px solid white;
        transition: all 0.2s ease-in;
        height: none;
        &:hover {
            color: white;
            background-color: $color-blue;
            transition: all 0.2s ease-in;
        }
    }
    .has-error .form-control {
        background-color: #ffbbb9;
    }
}
.alert {
    border-radius: none;
    h2, h3, h4, h5, p {
        color: white;
    }
    &-success {
        background-color: #3c763d;
    }
    &-danger {
        background-color: #a94442;
    }
}
