.section-burst {
	padding: 100px 10%;
	.burst-in-line {
			@extend .flex;
			@extend .flex-wrap;
			@extend .justify-around;
			.burst {
				width: 360px;
				margin: 10px;
				cursor: pointer;
				.content {
					position: relative;
					background-repeat: no-repeat;
					background-size: 100% 100%;
					background-position: center;
					@include transition(all 0.5s ease-in);

					.img-space {
						width: 100%;
						opacity: 0;
					}
					.overlay {
						top: 0;
						left: 0;
						opacity: 0;
						width: 100%;
						height: 100%;
						position: absolute;
						background: rgba(0, 0, 0, 0.5);
						//background-blend-mode: multiply;
						@include transition(all 0.5s ease-in);
					}
					.icon {
						position: absolute;
						top: 50%;
						width: 100%;
						height: 100%;
						left: 50%;
						@include transform(translate(-50%, -50%));
					}
					&:hover {
						background-size: 105% 105%;
						.overlay {
							opacity: 1;
							@include transition(all 0.5s ease-in);

							// For IE and Edge
							@media all and (-ms-high-contras: none), (-ms-high-contrast: active) {
								&:after {
									content: '';
									background: transparentize(rgba(0, 0, 0, 0.5), .3);
									position: absolute;
									top: 0;
									bottom: 0;
									width: 100%;
								}
							}
							// For IE and Edge
							@supports (-ms-ime-align:auto) {
								div:after {
									content: '';
									background: transparentize(rgba(0, 0, 0, 0.5), .3);
									position: absolute;
									top: 0;
									bottom: 0;
									width: 100%;
								}
							}
						}
					}
				}

				h3 {
					@include text-style(22px,#000,$font-family-1,400);
					text-align: center;
					line-height: 1.5;
					margin: 20px 0 10px 0;
					text-transform: uppercase;
				}
				p.cute-text {
					@include text-style(15px,#575757,$font-family-1,500);
					text-align: center;
					line-height: 1.2;
				}
				.btn-product {
					margin: auto;
					font-size: 18px;
					font-weight: 400;
					color: $color-blue;
					display: block;
					text-align: center;
				}
			}
		}
		.text-box {
			margin-top: 70px;
			p {
				@include text-style(24px,#000,$font-family-1,400);
				text-align: center;
				line-height: 2;
				width: 70%;
				margin: 0 auto;
			}
		}
	@media screen and (max-width: 1200px){
		.text-box {
			p {
				width: 100%;
				font-size: 18px;
			}
		}
	}
	@media screen and (max-width: 767px){
		padding: 50px 10px;
		.burst-in-line {
			flex-direction: column;
			.burst {
				width: 100%;
				max-width: 500px;
				margin: auto;
				margin-bottom: 20px;
			}
		}
	}
}
