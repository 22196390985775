$gutter: 30px;
.section-contact-form {
    background-size: contain;
    position: relative;
    padding: 0 3em;
    .title {
        text-align: center;
        color: $color-text-contrast;
        margin: $gutter / 2 auto 1em;
        width: 33%;
        font-weight: 400;
    }
    .overlay-1, .overlay-2, .overlay-3 {
        background-color: transparent;
        position: absolute;
    }
    .overlay-1 {
        top: $gutter;
        bottom: $gutter;
        left: $gutter;
        right: 66%;
        border-top: 2px solid $color-white;
        border-bottom: 2px solid $color-white;
        border-left: 2px solid $color-white;
    }
    .overlay-2 {
        top: $gutter;
        bottom: $gutter;
        left: 33%;
        width: 33%;
        border-bottom: 2px solid $color-white;
    }
    .overlay-3 {
        top: $gutter;
        bottom: $gutter;
        left: 66%;
        right: $gutter;
        border-top: 2px solid $color-white;
        border-bottom: 2px solid $color-white;
        border-right: 2px solid $color-white;
    }
    .form-control {
        margin-bottom: $gutter;
    }
    .g-recaptcha {
        margin-bottom: $gutter / 2;
    }
    .btn-send {
        transform: translateY($gutter / 2);
    }

}

