.section-burst-bottom {
	background-position: center;
	background-size: cover;
	text-align: center;
	@extend .flex;
	@extend .justify-end;
	//@extend .items-center;
	.right {
		width: 50%;
		h3 {
			@include text-style(3.5vw,#fff,$font-family-1,300);
			line-height: 1.2;
			text-align: left;
			@include transition(all 0.5s ease-in);
			margin-top: 50px;
		}
		.img-container {
			min-width: 100%;
			img {min-width: 100%;}
			position: relative;
			.overlay {
				position: absolute;
				width: 100%;
				top: 0;
				left: 0;
				opacity: 0;
				height: 100%;
				background-size: 100% 100%;
				@include transition(all 0.5s ease-in);
			}
		}
		&:hover {
			h3 {
				color: #000;
				@include transition(all 0.5s ease-in);
			}
			.img-container {
				.overlay {
					opacity: 1;
					@include transition(all 0.5s ease-in);
				}
			}
		}
	}
	@media screen and (max-width: 768px){
		.right {
			width: 50%;
			h3 {
				font-size: 24px;
				text-align: right;
				margin-right: 30px;
				margin-left: 15%;
				margin-top: 10px;
			}
		}
	}
	@media screen and (max-width: 500px){
		.right {
			width: 65%;
			h3 {
				font-size: 20px;
				margin-right: 20px;
			}
		}
	}
}
