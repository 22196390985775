// IMPORT ADDONS
@import 'addons/bootstrap/bootstrap';
// @import 'addons/font-awesome/font-awesome';
// @import 'addons/owl-carousel2/owl.carousel';
@import 'addons/layerslider/layerslider';
@import 'addons/magnific-popup/magnific-popup';
// @import 'addons/cube-portfolio/cubeportfolio';
// @import 'addons/parallax-scroll/normalize';
// @import 'addons/parallax-scroll/main';

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'reset';
@import 'variables';
@import 'functions';

/* GENERAL TEMPLATE */
html {
	font-size: $font-size-base; // used for rem units
}
body {
	background-color: $bg-color-body;
	@extend .text-color;
	@extend .font-1;
	line-height: 1;
	a {
		@extend .text-color-gold;
		@extend .font-1;
		text-decoration: none !important;
		&:hover, &:focus {
			text-decoration: none !important;
			 @extend .text-color-contrast;
		}
	}
	a[href^=tel], a[href^=mailto] {
		&:hover {

		}
	}
	h1.referencement {
		display: none !important;
	}
	.appear {
		opacity: 0;
		@include transition(opacity 0.75s ease-in);
	}
	.parallax, .parallax-slow, .parallax-fast {
		background-attachment: fixed;
		@media screen and (max-width: 768px) {
			background-attachment: scroll;
		}
	}
}

h1, h2, h3, h4, h5, h6 {
	@extend .font-1;
	margin: 0;
}

p, li {
    @extend .font-main;
}

.body {
	overflow-x: hidden;
	background-color: $bg-color-body;
}

/** Add this class to any anchor tag so it clear the fixed menu (used in forms) */
.jumptarget::before {
  content:"";
  display:block;
  height:$size-nav-height; /* fixed header height*/
  margin:-$size-nav-height 0 0; /* negative fixed header height */
}


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/*****************************************************/
@import 'menu';

/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'footer';

/*****************************************************/
/* Form (to use with /modules/module-*-form.php)
/*****************************************************/
@import 'form';

/*****************************************************/
/* Sections
/*****************************************************/

@import 'sections/section-burst';

@import 'sections/section-phone';

@import 'sections/section-video';

@import 'sections/section-burst-bottom';

@import 'sections/section-logos-bottom';

@import 'sections/section-image-text';

@import 'sections/section-burst-icon';

@import 'sections/section-contact-form';

@import 'sections/section-nouveautes';

@import 'sections/section-videos';

@import 'sections/section-projets';


/*****************************************************/
/* LOI 25
/*****************************************************/

a.btn-loi {
    width: 100px;
    position: fixed;
    left: 10px;
    z-index: 100;
    bottom: 15px;
    @media screen and (max-width: $size-xs-max) {
        width: 75px;
        left: initial;
        right: 10px;
    }
}

/*******************************************************************************
* COMMON TO ALL PAGES
*/
section.header {
	background-repeat: no-repeat;
	height: calc(100vw * 600 / 1920);
    background-size: contain;
	@extend .flex;
	@extend .justify-center;
	@extend .items-center;
	h2 {
		@extend .text-color-contrast;
		text-align: center;
		font-size: 3vw;
		font-weight: 300;
		text-transform: uppercase;
		transform: translateY(-30px);
		span.subtitle {
			font-weight: 500;
			font-size: 2.2vw;
			letter-spacing: 1vw;
		}
	}
}

h3.page-subtitle, div.page-description {
    margin: 0 auto 50px;
    max-width: 900px;
}
h3.page-subtitle {
    text-align: center;
    font-size: 3vw;
    color: $color-blue;
    font-weight: 300;
}
div.page-description p,
div.page-description li {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
}

section.logos {
    padding: 50px 0;
    hr {
        width: 90%;
        border-color: $color-blue;
        // border-width: 2px;
    }
    hr:last-child {
        display: none;
    }
}

.index {
    .section-burst-bottom {
        background-image: url('../images/body_section_04_bg.jpg');
    }
    .section-logos-bottom .carousel .icon { width: 200px;}
}

/*******************************************************************************
* NOUVEAU PARALLAX - ACCUEIL
*/
#section-parallax {
    background: url(../images/accueil_section02_parallax_bg.jpg) no-repeat;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    min-height: 500px;
    border-top: 4px solid #165C79;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 2%;
    h3 {
        font-weight: bold;
        font-size: 46px;
        color: #fff;
        text-align: center;
        text-transform: initial;
        text-shadow: 1px 1px 15px #000;
        padding-bottom: 35px;
    }
    .list {
        display: flex;
        width: 100%;
        justify-content: center;
        .left-side {
            width: 18%;
        }
        ul {
            list-style: none; 
            li {
                font-weight: bold;
                font-size: 26px;
                color: #fff;
                text-transform: initial;
                line-height: 1.3;
                text-shadow: 1px 1px 15px #000;
                margin-bottom: -20px;
            }
            li::before {
                content: "•";
                display: inline-block; 
                width: 0.8em;
                color: #fff;
                font-size: 45px;
                text-shadow: 1px 1px 15px #000;
            }
        }
    }
    a.bouton {
        background: #fff;
        padding: 15px;
        width: 23%;
        text-align: center;
        margin-top: 35px;
        font-weight: bold;
        font-size: 22px;
        color: #218cb8;
        transition: 0.3s;
        &:hover {
            color: #fff;
            background: #218cb8;
            transition: 0.3s;
        }
    }
}

/*******************************************************************************/
//link D-COR Page Domotique residentiel
.link-d-cor {
	background: $color-black;
	width: 100%;
	padding: 10px;
	.border {
		width: 100%;
		height: 100%;
		padding: 20px 0;
		text-align: center;
		border: 1px solid $color-white;
		p {
			color: $color-white;
			font-weight: 500;
			margin-bottom: 1vw;
		}
		img{margin: auto;}
		&:hover {
			p {color: #ddff45;}
		}
	}

}
/*******************************************************************************
* DOMOTIQUE PAGES OVERRIDES
*/
.domotique {
    .section-image-text {
        background-image: none;
        .text {
            .title {
                margin-top: 0px;
            }
        }
    }
    .section-contact-form {
        background-image: url('../images/contact_burst_bg.jpg');
    }

    .section-burst-bottom {
        background-image: url('../images/body_section_04_bg.jpg');
    }
}

/*******************************************************************************
* DOMOTIQUE RESIDENTIELLE OVERRIDES
*/
.domotique.residentiel {
	section.header {
		background-image: url('../images/residentiel_header_bg_v2.jpg');
        transition: all .2s linear;
        &:hover {
            background-image: url('../images/residentiel_header_bg_v2_hover.jpg');
            transition: all .2s linear;
        }
	}
    &.english {
        section.header {
            &:hover {
                background-image: url('../images/residentiel_header_bg_hover_en.jpg');
            }
        }
    }
    .section-image-text {
        &.top {
            background-image: url('../images/residential_body_section_01_bg.jpg');
            min-height: calc(100vw * 900 / 1940);
            .image {
                img {
                    transform: translateY(-10%);
                }
            }
        }
        &.bottom {
            background-image: url('../images/residential_body_section_02_bg.jpg');
            min-height: calc(100vw * 900 / 1940);
            .text {
                .title {
                    font-size: 2vw;
                    margin-bottom: 50px;
                    margin-top: 50px;
                }
            }
        }
    }
}

/*******************************************************************************
* DOMOTIQUE SONORISATION OVERRIDES
*/
.domotique.sonorisation {
    section.header {
        background-image: url('../images/sonorisation_header_bg.jpg');
    }
}

/*******************************************************************************
* DOMOTIQUE CINEMA MAISON OVERRIDES
*/
.domotique.cinema-maison {
    section.header {
        background-image: url('../images/cinema_maison_header_bg.jpg');
    }
}

/*******************************************************************************
* DOMOTIQUE MAISON INTELLIGENTE OVERRIDES
*/
.domotique.maison-intelligente {
    section.header {
        background-image: url('../images/maison_intelligente_header_bg.jpg');
    }
    .section-image-text {
        .image {
            @extend .flex-column;
            @extend .flex;
            @extend .justify-between;
            & > div.container-fluid {
                @extend .w100;
                @extend .flex;
                @extend .flex-1;
                @extend .items-center;
                .row {
                    @extend .w100;
                }
            }
        }
    }
}

/*******************************************************************************
* DOMOTIQUE COMMERCIALE OVERRIDES
*/
.domotique.commercial {
    .section-burst-bottom {
        background-image: url('../images/body_section_04_bg_img_02.jpg');
    }
}
/*******************************************************************************
* DOMOTIQUE COMMERCIALE CLE EN MAIN OVERRIDES
*/
.domotique.commercial.cle {
    section.header {
        background-image: url('../images/cle_en_main_header_bg.jpg');
    }

}

/*******************************************************************************
* DOMOTIQUE COMMERCIALE CONFERENCE OVERRIDES
*/
.domotique.commercial.conference {
    section.header {
        transition: all .2s linear;
        background-image: url('../images/salles_de_conference_header_bg_v2.jpg');
        &:hover {
            transition: all .2s linear;
            background-image: url('../images/salles_de_conference_header_bg_v2_hover.jpg');
        }
    }
    .section-image-text {
        min-height: calc(100vw * 1000 / 1940);
        .image {
            img {
                transform: translateY(-10%);
            }
        }
        .text {
            p {
                margin-bottom: 2vw;
            }
        }
    }
    &.english {
        section.header {
            &:hover {
                background-image: url('../images/salles_de_conference_header_bg_v2_en.jpg');
            }
        }
    }
}

/*******************************************************************************
* DOMOTIQUE COMMERCIALE DESIGNERS OVERRIDES
*/
.domotique.commercial.designers {
    section.header {
        transition: all .2s linear;
        background-image: url('../images/designers_header_bg.jpg');
    }
    .section-logos-bottom.accreditations .carousel .icon { width: 200px;}
}

/*******************************************************************************
* DOMOTIQUE COMMERCIALE ENTREPRENEURS OVERRIDES
*/
.domotique.commercial.entrepreneurs {
    section.header {
        transition: all .2s linear;
        background-image: url('../images/entrepreneurs_header_bg.jpg');
    }
    .section-logos-bottom.accreditations .carousel .icon { width: 200px;}
}

/*******************************************************************************
* NOUVEAUTES DOMOTIQUES OVERRIDES
*/
.domotique.nouveautes {
    section.header {
        transition: all .2s linear;
        background-image: url('../images/nouveautes_header_bg.jpg');
    }
}

/*******************************************************************************
* VIDEOS DOMOTIQUES OVERRIDES
*/
.domotique.videos {
    section.header {
        transition: all .2s linear;
        background-image: url('../images/video_header_bg.jpg');
    }
}

/*******************************************************************************
* REALISATIONS RESIDENTIELLES OVERRIDES
*/
.domotique.realisation.residentielle {
    section.header {
        transition: all .2s linear;
        background-image: url('../images/resi_realisations_header_bg.jpg');
    }
}

/*******************************************************************************
* REALISATIONS FICHES PROJETS OVERRIDES
*/


/*******************************************************************************
* REALISATIONS COMMERCIALES OVERRIDES
*/
.domotique.realisation.commerciale {
    section.header {
        transition: all .2s linear;
        background-image: url('../images/comm_realisations_header_bg.jpg');
    }
}

/*******************************************************************************
* ZONES OVERRIDES
*/
.domotique.zones {
    section.header {
        transition: all .2s linear;
        background-image: url('../images/zones_desservies_header_bg.jpg');
    }
    .section-logos-bottom.accreditations .carousel .icon { width: 200px;}
}

/*******************************************************************************
* CONTACT OVERRIDES
*/
.domotique.contact {
    section.header {
        transition: all .2s linear;
        background-image: url('../images/contact_header_bg.jpg');
    }
    .section-image-text .text {
        h4 {
            margin-bottom: 20px;
            margin-top: 20px;
            font-weight: 300;
            color: $color-black;
        }
        div.blue h4 {
            color: $color-white;
            margin-top: 0px;
        }
    }
    a {
        color: $color-blue;
        &:hover {
            color: $color-black;
        }
    }
    .section-logos-bottom.accreditations .carousel .icon { width: 200px;}
    &.english {
        section.header {
            background-image: url('../images/contact_header_bg.jpg');
        }
    }
}

/*******************************************************************************
* MAGNIFIC POPUP OVERRIDES
*/
.mfp-iframe-holder .mfp-content {
    width: 100%;
    max-width: 900px;
    height: 100%;
}
.section-popup {
    // height: 100vh;
    overflow: auto;
    -ms-overflow-x: hidden;
    .popup-container {
        .img-container {
            height: 100%;
        }
        .info {
            margin-left: 20px;
            h3 {
                @include text-style(22px,#000,$font-family-1,400);
                text-align: center;
                line-height: 1.5;
            }
            p {
                @include text-style(15px,#575757,$font-family-1,500);
                text-align: center;
                line-height: 1.2;
            }
        }
    }
}

/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/


@media screen and (max-width: $size-sm-max){
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */
    h3.page-subtitle, div.page-description {
        margin: 0 auto 40px;
        max-width: 700px;
    }
    h3.page-subtitle {
        font-size: 6vw;
    }
    div.page-description p {

    }
    .domotique {
        &.residentiel,
        &.cinema-maison,
        &.sonorisation,
        &.maison-intelligente,
        &.commercial.cle,
        &.commercial.conference,
        &.commercial.designers,
        &.commercial.entrepreneurs,
        &.nouveautes,
        &.videos,
        &.realisation.residentielle,
        &.realisation.commerciale,
        &.zones
        {
            .section-image-text.mb-10 {
                margin-bottom: 0;
            }
            .section-image-text, .section-image-text.top, .section-image-text.bottom {
                background-image: none;
                .image {
                    img {
                        transform: translateY(0);
                    }
                }
            }
            .section-image-text.bottom {
                .text {
                    .title, .subtitle {
                        margin-bottom: 50px;
                        margin-top: 50px;
                    }
                    .title {
                        font-size: 1.8em;
                    }
                    .subtitle {
                        font-size: 1em;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1715px) {
   
#section-parallax .list .left-side {
    width: 25%;
}
#section-parallax a.bouton {
    width: 28%;
}
}

@media screen and (max-width: 1375px) {
   
#section-parallax a.bouton {
    width: 30%;
}
}

@media screen and (max-width: $size-md-max) {
   
#section-parallax a.bouton {
    width: 35%;
}
}

@media screen and (max-width: 1024px) {

#section-parallax .list .left-side {
    width: 38%;
}
#section-parallax a.bouton {
    width: 45%;
}
}

@media screen and (max-width: $size-sm-max) {

#section-parallax .list .left-side {
    width: 41%;
}
#section-parallax a.bouton {
    width: 57%;
}
}

@media screen and (max-width: $size-xs-max){

#section-parallax {
    background: url(../images/accueil_section02_bg_m.jpg) no-repeat;
    padding: 50px 2%;
}
#section-parallax .list {
    flex-direction: column;
}
#section-parallax h3 br {
    display: none;
}
#section-parallax h3 {
    font-size: 28px;
    padding-bottom: 20px;
}
#section-parallax .list ul li {
    font-size: 20px;
}
#section-parallax .list ul li::before {
    display: initial;
    padding-right: 15px;
}
#section-parallax .list .left-side {
    width: 100%;
}
#section-parallax .list .right-side {
    margin-top: -10px;
}
#section-parallax a.bouton {
    width: 100%;
    font-size: 20px;
}
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */
    section.header {
        height: calc(100vw * 600 / 480);
        h2 {
            font-size: 8vw;
            // transform: translate(0,0);
            span.subtitle {
                font-size: 0.8em;
                letter-spacing:0.2em;
            }
        }
    }

    /*******************************************************************************
    * DOMOTIQUE RESIDENTIELLE OVERRIDES
    */
    .domotique.residentiel {
        section.header {
            background-image: url('../images/residentiel_header_bg_m_v2.jpg');
            transition: all .2s linear;
            &:hover {
                background-image: url('../images/residentiel_header_bg_m_v2.jpg');
                transition: all .2s linear;
            }
        }
        &.english {
            section.header {
                background-image: url('../images/residentiel_header_bg_m_v2_en.jpg');
                &:hover {
                    background-image: url('../images/residentiel_header_bg_m_v2_en.jpg');
                }
            }
        }
    }

    /*******************************************************************************
    * DOMOTIQUE SONORISATION OVERRIDES
    */
    .domotique.sonorisation {
        section.header {
            background-image: url('../images/sonorisation_header_bg_m.jpg');
        }
        .section-image-text.top {
            .image {
                img {
                    transform: translateY(0);
                }
            }
        }
        .section-image-text.bottom {
            background-image: none;
            min-height: inherit;
            .text {
                .title {
									font-size: 1.3em;
                }
            }
        }
    }

    /*******************************************************************************
    * DOMOTIQUE CINEMA MAISON OVERRIDES
    */
    .domotique.cinema-maison {
        section.header {
            background-image: url('../images/cinema_maison_header_bg_m.jpg');
        }
    }

    /*******************************************************************************
    * DOMOTIQUE MAISON INTELLIGENTE OVERRIDES
    */
    .domotique.maison-intelligente {
        section.header {
            background-image: url('../images/maison_intelligente_header_bg_m.jpg');
        }
    }

    /*******************************************************************************
    * DOMOTIQUE COMMERCIAL CLE EN MAIN OVERRIDES
    */
    .domotique.commercial.cle {
        section.header {
            background-image: url('../images/cle_en_main_header_bg_m.jpg');
        }
    }

    /*******************************************************************************
    * DOMOTIQUE COMMERCIAL CONFERENCES OVERRIDES
    */
    .domotique.commercial.conference {
        section.header {
            background-image: url('../images/salles_de_conference_header_bg_m.jpg');
            &:hover {
                background-image: url('../images/salles_de_conference_header_bg_m.jpg');
            }
        }
    }

    /*******************************************************************************
    * DOMOTIQUE COMMERCIAL DESIGNERS OVERRIDES
    */
    .domotique.commercial.designers {
        section.header {
            background-image: url('../images/designers_header_bg_m.jpg');
        }
    }

    /*******************************************************************************
    * DOMOTIQUE COMMERCIAL ENTREPRENEURS OVERRIDES
    */
    .domotique.commercial.entrepreneurs {
        section.header {
            background-image: url('../images/entrepreneurs_header_bg_m.jpg');
        }
    }

    /*******************************************************************************
    * NOUVEAUTES DOMOTIQUES OVERRIDES
    */
    .domotique.nouveautes {
        section.header {
            background-image: url('../images/nouveautes_header_bg_m.jpg');
        }
    }

    /*******************************************************************************
    * VIDEOS DOMOTIQUES OVERRIDES
    */
    .domotique.videos {
        section.header {
            transition: all .2s linear;
            background-image: url('../images/video_header_bg_m.jpg');
        }
    }

    /*******************************************************************************
    * REALISATIONS RESIDENTIELLES OVERRIDES
    */
    .domotique.realisation.residentielle {
        section.header {
            transition: all .2s linear;
            background-image: url('../images/resi_realisations_header_bg_m.jpg');
        }
    }

    /*******************************************************************************
    * REALISATIONS COMMERCIALES OVERRIDES
    */
    .domotique.realisation.commerciale {
        section.header {
            transition: all .2s linear;
            background-image: url('../images/comm_realisations_header_bg_m.jpg');
        }
    }

    /*******************************************************************************
    * ZONES OVERRIDES
    */
    .domotique.zones {
        section.header {
            transition: all .2s linear;
            background-image: url('../images/zones_desservies_header_bg_m.jpg');
        }
    }

    /*******************************************************************************
    * CONTACT OVERRIDES
    */
    .domotique.contact {
        section.header {
            transition: all .2s linear;
            background-image: url('../images/contact_header_bg_m.jpg');
        }
    }
}
