.section-logos-bottom {
	background: #fff;
	padding: 20px 5% 0;
	max-width: 100%;
	margin: auto;
	.title {
		text-align: center;
		margin-bottom: 0px;
	}
	.carousel {
		@extend .flex;
		@extend .justify-between;
		@extend .items-center;
		.icon {
			width:100px;
			margin: 0 10px;
		}
		p {
			@include text-style(18px,#676767,$font-family-1,400);
			text-align: center;
		}
	}
	@media screen and (max-width: 991px) {
		.carousel {
			flex-wrap: wrap;
			p { font-size: 11px; }
		}
	}
	@media screen and (max-width: 500px) {
		.carousel {
			flex-wrap: wrap;
			p { font-size: 11px; }
			.icon {
				width: 100px;
			}
		}
	}
}
