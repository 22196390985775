.section-projets {
    p {
        font-size: 16px;
    }
    &--item {
        cursor: pointer;
        margin-bottom: 8vh;
        margin-left: 5vw;
        padding-right: 15vw;
        position: relative;
        background-image: linear-gradient(to right, $color-blue, $color-blue-dark);
        .overlay {
            mix-blend-mode: multiply;
            background-color: grey;
            background-image: url(../images/icon_magnifier.png);
            background-repeat: no-repeat;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            transition: all 0.3s ease-out;
        }
        &:hover {
            .section-projets--image {
                img {
                    transform: scale(1.1, 1.1);
                    transition: 0.2s all ease-in-out;
                }
            }
            .overlay {
                opacity: 1;
                background-position: center, center;
                // IE 10 and 11
                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    opacity: 0.5;
                }
                // For IE and Edge
                @supports (-ms-ime-align:auto) {
                    opacity: 0.5;
                }
            }
        }
    }
    &--image {
        max-height: 175px;
        overflow: hidden;
        margin-right: 2vw;
        img {
            transition: 0.2s all ease-in-out;
        }
    }
    &--text {
        padding: 10px 0;
    }
    &--title {
        h3 {
            font-size: 1.5vw;
            font-weight: 400;
            margin-bottom: 10px;
            color: $color-white;
        }
    }
    &--description {
        p, li {
            color: $color-white;
        }
    }
}
.amazingslider-wrapper-1 {
    .amazingslider-title-1 {
        color: $color-white;
        font-weight: 500;
        font-size: 16px
    }
    .amazingslider-description-1 p,
    .amazingslider-description-1 li {
        color: $color-white;
        font-size: 14px;
    }
    .amazingslider-bullet-1 {
        box-sizing: content-box;
        color: $color-white;
    }
}

.fiche.projet {
    h3.page-subtitle, div.page-description {
        margin: 25px auto;
        max-width: 900px;
    }
    h3.page-subtitle {
        text-align: center;
        font-size: 30px;
        color: $color-blue;
        font-weight: 300;
    }
    div.page-description p,
    div.page-description li {
        font-size: 12px;
        font-weight: 400;
        line-height: 1.5;
    }
}

/*******************************************************************************
* MOBILE OVERRIDES
*/

@media (max-width: $size-sm-max) {
    .section-projets {
        &--item {
            margin-left: 5vw;
            padding-right: 5vw;
        }
        &--image {
            max-height: 220px;
            overflow: hidden;
            margin-right: 3vw;
            img {
                transition: 0.2s all ease-in-out;
            }
        }
        &--title {
            h3 {
                font-size: 16px;
                font-weight: 00;
                margin-bottom: 10px;
            }
        }
        &--description {
            p, li {
                font-size: 14px;
                line-height: 1.2;
            }
        }
    }
    .amazingslider-wrapper-1 {
        .amazingslider-title-1 {
            font-weight: 500;
            font-size: 14px
        }
        .amazingslider-description-1 p,
        .amazingslider-description-1 li {
            font-size: 12px;
        }
    }
}
@media (max-width: $size-xs-max) {
    .section-projets {
        &--item {
            margin-left: auto;
            margin-right: auto;
            padding-right: 0;
            flex-direction: column;
            max-width: 600px;
        }
        &--image {
            max-height: 80vw;
            margin-right: 0;
        }
        &--text {
            padding: 5vw;
        }
    }
}
