html {
    // To add lining figures
    -webkit-font-feature-settings: "lnum";
    -moz-font-feature-settings: "lnum";
    -ms-font-feature-settings: "lnum";
    font-feature-settings: "lnum";

    // to prevent iphone and ipad side sliding
    margin: 0;
    padding: 0;
    overflow: hidden;
}

body {
    // to prevent iphone and ipad side sliding
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: auto;
}