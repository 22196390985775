.section-video-index {
	background-image: url('../images/body_section_03_bg.jpg');
	background-position: center;
	background-size: cover;
	text-align: center;
	.container-fluid {
		padding: 0;
		iframe {
			height: 480px;
			width: 854px;
		}
	}
	@media screen and (max-width: 854px) {
		.container-fluid {
			iframe {
				width: 100%;
				height: calc(100vw * 480 / 854 );
			}
		}
	}
}
