.section-phone-index {
	background-image: url('../images/body_section_02_bg.jpg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 250px;
	border-top: 4px solid #165C79;
	@extend .flex;
	//@extend .justify-around;
	//
	.left {
		@extend .flex;
		@extend .justify-center;
		@extend .items-center;
		height: 100%;
		width: 50%;
    padding-right: 10px;
		background: rgba(0, 0, 0, 0.5);
		p {
			@include text-style(22px,#fff,$font-family-1,400);
			text-align: center;
		}
		h3 {
			@include text-style(60px,#fff,$font-family-1,500);
			float: left;
			margin: 0;
		}
		img {
			padding-left: 15px;
			float: left;
		}
	}
	.right {
		@extend .flex;
		@extend .justify-center;
		@extend .items-center;
		height: 100%;
		width: 50%;
		@include transition(all 0.5s ease-in);
		.content {
			text-align: center;
			.phone-icon {
				margin: auto;
				width: 73px;
				height: 73px;
				img {opacity: 0;}
				background-image: url('../images/icon_phone.png');
				background-repeat: no-repeat;
				background-position: center;
				background-size: 100% 100%;
				@include transition(all 0.5s ease-in);
			}
			p {
				@include text-style(24px,#000,$font-family-1,400);
				text-transform: uppercase;
				line-height: 1.2;
				margin-top: 10px;
				@include transition(all 0.5s ease-in);
			}
			h3 {
				@include text-style(35px,#218CB8,$font-family-1,500);
				@include transition(all 0.5s ease-in);
			}
		}
		&:hover {
			background: $color-blue;
      background-image: url('../images/body_section_02_bg_r.jpg');
      background-position: left;
      background-size: cover;
			@include transition(all 0.5s ease-in);
			background-blend-mode: multiply;
			.content {
				.phone-icon {
					background-image: url('../images/icon_phone_hover.png');
					@include transition(all 0.5s ease-in);
				}
				p, h3 {
					color: #fff;
					@include transition(all 0.5s ease-in);
				}
			}
		}
	}
	@media screen and (max-width: 991px) {
		.left {
			p {font-size: 16px;}
			h3 {font-size: 40px;}
		}
		.right {
			.content {
				p {font-size: 18px;}
				h3 {font-size: 25px;}
			}
		}
	}
	@media screen and (max-width: 767px) {
		flex-direction: column;
		height: 500px;
		.left, .right {width: 100%;}
		.left {
			flex-direction: column-reverse;
			p, h3 {margin-top: 10px;}

		}
	}
}
