/*****************************************************/
// MENU
/* needs main.js to toggle var menu = ".menu-mobile"; */

nav.module-menu {
    position: relative;
    padding-bottom: $size-nav-height;
    @media screen and (max-width: $size-nav-shift-breakpoint) {
        padding-bottom: $size-nav-height-mobile;
    }
    ul {
        list-style-type: none;
    }

    .menu-desktop {
        z-index: 100;
        width: 100%;
        @extend .bg-color-nav;
        @include transition(all 0.2s ease);
        height: $size-nav-height;
        @media screen and (max-width: $size-nav-shift-breakpoint) {
            height: $size-nav-height-mobile;
        }
        @extend .flex;
        position: fixed;
        .wrapper {
            &.left {
                flex: 0 0 auto;
                .logo {
                    height: 100%;
                    width: auto;
                    @extend .flex;
                    @extend .justify-center;
                    @extend .items-center;
                    @extend .px6;
                    a {
                        @extend .text-color-nav;
                        @extend .font-nav-logo;
                        &:hover {
                            @extend .text-color-nav-hover;
                        }
                    }
                    @media screen and (max-width: $size-nav-shift-breakpoint){
                        max-width: 275px;
                    }
                    @media screen and (max-width: 500px){
                        max-width: 200px;
                    }
                }
            }
            &.right {
                flex: auto;
                @extend .flex;
                @extend .flex-column;
                @extend .justify-center;
                @extend .items-end;
                .main-menu-top, .main-menu {
                    height: 50%;
                    @media screen and (max-width: $size-nav-toggle-breakpoint) {
                        display: none;
                    }
                    & > ul {
                        @extend .flex;
                        @extend .flex-wrap;
                        @extend .justify-end; // pour menu a droite
                        // @extend .justify-center; // pour menu au centre de l'espace
                        // @extend .justify-start; // pour menu a gauche
                        @extend .items-center; // pour menu centré verticalement
                        // @extend .items-start; // pour menu en haut
                        // @extend .items-end; // pour menu en bas
                        padding: 0 10px 0 0;
                        @extend .m0;
                        height: 100%;
                        li {
                            height: 100%;
                            transition: all linear 0.1s;
                            @extend .flex;
                            @extend .items-center;
                            // @extend .items-start; // pour menu en haut
                            // @extend .items-end; // pour menu en bas
                            @extend .pr4;
                            &.icon {
                              padding-right: 40px;
                            }
                            &.phone, &.lang {
                                & > a, & > span.menu-title {
                                    @extend .font-nav-phone;
                                    @extend .text-color-nav-phone;
                                    span.ville {
                                      color: $color-black;
                                    }
                                }
                                &:hover > a, &:hover > span.menu-title {
                                    @extend .text-color-nav-phone-hover;
                                }
                            }
                            &.lang {
                                @extend .pr8;
                                & > a, & > span.menu-title {
                                    color: $color-black;
                                }
                            }
                        }
                    }
                }
                .main-menu {
                    & > ul {
                        li {
                            & > a, & > span.menu-title {
                                @extend .px2;
                                @extend .text-color-nav;
                                @extend .font-nav-items;
                                // padding: 0 15px 20px;
                                transition: all linear 0.1s;
                            }
                            &:hover {
                                & > a, & > span.menu-title {
                                    @extend .text-color-nav-hover;
                                }
                            }
                            &.active, &:active {
                                div, span.menu-title {
                                    @extend .text-color-nav-active;
                                }
                            }
                            &.dropdown:hover + .submenu,
                            &.dropdown:hover > .submenu {
                                opacity: 100;
                                visibility: visible;
                            }

                            .submenu {
                                opacity: 0;
                                visibility: hidden;
                                //@extend .flex;
                                //@extend .justify-end;
                                position: absolute;
                                top: $size-nav-height;
                                @media screen and (max-width: $size-nav-shift-breakpoint) {
                                    top: $size-nav-height-mobile;
                                }
                                //left: 0;
                                //width: 100%;
                                padding: 10px 0 0 10px;
                                background-color: rgba(#fff, 0.9);
                                transition: opacity 0.1s ease-in;
                                border-bottom: 2px solid black;
                                border-top: 1px solid #999;
                                ul {
                                  //  @extend .flex;
                                  //  @extend .flex-wrap;
                                  //  @extend .justify-start;
                                  //  flex: 1 1 auto;
                                    // @extend .m0;
                                    @extend .p0;
                                    li.subitem {
                                      //  width: 50%;
                                        //height: $size-nav-subitem;
                                        &:hover {
                                          //  @extend .bg-color-nav-hover;
                                        }
                                        &:active {
                                          //  @extend .bg-color-nav-active;
                                        }
                                        & > a {
                                            @extend .font-nav-drop-items;
                                            padding: 20px 10px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .main-menu-top {
                    ul {
                      li {
                        a {
                          border-right: 1px solid $color-black;
                          padding-right: 1rem;
                        }
                        &:nth-last-child(1){
                          a {
                            border-right: none;
                            padding-right: .5rem;
                          }
                        }
                      }
                    }
                }
            }
        }
    }
    &.scrolled {
        .menu-desktop {
            position: fixed;
        }
    }
    .empty-line, .white-line {
        bottom: -4px;
        height: 3px;
        width: 100%;
        background-color: transparent;
        position: absolute;
    }

    .white-line {
        bottom: -10px;
        background-color: $color-white;
    }
    .menu-toggle {
        @media screen and (min-width: $size-nav-toggle-breakpoint) {
            display: none;
        }
        cursor: pointer;
        @extend .pr3;
        .bar-top, .bar-bottom {
            box-shadow: 0px 0px 4px $color-black;
            width: 100%;
            height: $size-nav-toggle-bar-height;
            display: block;
            @extend .bg-color-nav-toggle;
        }
        .bar-top {
            margin-bottom: 3px;
        }
        .bar-bottom {
            margin-top: 1px;
        }
        .word {
            text-shadow: 0px 0px 6px white;
            @extend .font-1-bold;
            color: #000;
        }
    }
    /************ menu mobile ***************************/
    #menu-mobile {
        width: 100%;
        top: 0;
        right: -100%;
        z-index: 1200;
        // overflow: hidden;
        position: fixed;
        @extend .bg-color-nav;
        @include transition(all 0.3s ease-in);
        @media screen and (max-width: $size-nav-toggle-breakpoint) {
            &.toggled {
                overflow-y: auto;
                bottom: 0; // pour avoir le scroll
                @include transform(translate(-100%, 0%));
            }
        }
        .menu-toggle {
            float: right;
            margin: 15px 0 0 0;
        }
        ul {
            margin: 25px auto;
            padding: 30px;
            li {
                // position: relative;
                text-align: center;
                line-height: 100%;
                padding: 15px;
                border-bottom: 1px solid $color-2;
                &:nth-last-child(1) {
                    border-bottom: none;
                }
                & > a, & > span.menu-title {
                    @extend .font-nav-items;
                    @extend .text-color-nav;
                }
                .submenu {
                    margin: 0px auto;
                    padding: 10px 0 10px;
                    ul {
                        margin: 0;
                        padding: 0;
                    }
                    li {
                        border-bottom: none;
                        padding-top: 10px;
                        text-align: center;
                        a {
                            @extend .font-nav-drop-items;
                            @extend .text-color-nav;
                            font-weight: 400;
                        }
                        &:hover, &.active {
                            a {
                                @extend .text-color-nav-hover;
                            }
                        }
                    }
                }
                &:hover, &.active, &.phone {
                    a {
                       @extend .text-color-nav-hover;
                       span {color: #fff;}
                      }
                }
            }
        }
    }
}
